import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  JSON: { input: any; output: any; }
};

export type ContactFormData = {
  key: Scalars['String']['input'];
  label: Scalars['String']['input'];
  value?: InputMaybe<Scalars['JSON']['input']>;
};

export type ExpiredUser = {
  emailAddress?: Maybe<Scalars['String']['output']>;
  /** First and last name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** 5 digit hex string of user id */
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
};

export type Mutation = {
  submitContactForm?: Maybe<SubmitContactFormResponse>;
};


export type MutationSubmitContactFormArgs = {
  input: SubmitContactFormInput;
};

export type Query = {
  /**
   * When a user returns to the site with a token that was valid but is no longer expired,
   * this property gives limited access to information such as their email, name, and user ID,
   * to assist with them logging in again. If the user is currently logged in, this property will return null.
   */
  expiredMe?: Maybe<ExpiredUser>;
  /** Information for the currently logged in user */
  me?: Maybe<User>;
};

export type SubmitContactFormInput = {
  formFieldsResponses: Array<ContactFormData>;
  formId: Scalars['ID']['input'];
  leadSource: Scalars['String']['input'];
};

export type SubmitContactFormResponse = {
  status?: Maybe<Scalars['String']['output']>;
};

/** User's information and current status, along with their company record */
export type User = {
  emailAddress?: Maybe<Scalars['String']['output']>;
  /** First and last name */
  firstName?: Maybe<Scalars['String']['output']>;
  /** 5 digit hex string of user id */
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  partnerId?: Maybe<Scalars['ID']['output']>;
};

export type GetUserValidationQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserValidationQueryType = { me?: { id: string, firstName?: string | null, lastName?: string | null, emailAddress?: string | null, partnerId?: string | null } | null, expiredMe?: { id: string, firstName?: string | null, lastName?: string | null, emailAddress?: string | null } | null };

export type SubmitContactFormMutationVariables = Exact<{
  input: SubmitContactFormInput;
}>;


export type SubmitContactFormMutationType = { submitContactForm?: { status?: string | null } | null };


export const GetUserValidationQuery = gql`
    query getUserValidation {
  me {
    id
    firstName
    lastName
    emailAddress
    partnerId
  }
  expiredMe {
    id
    firstName
    lastName
    emailAddress
  }
}
    `;

/**
 * __useGetUserValidationQuery__
 *
 * To run a query within a React component, call `useGetUserValidationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserValidationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserValidationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserValidationQuery(baseOptions?: Apollo.QueryHookOptions<GetUserValidationQueryType, GetUserValidationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserValidationQueryType, GetUserValidationQueryVariables>(GetUserValidationQuery, options);
      }
export function useGetUserValidationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserValidationQueryType, GetUserValidationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserValidationQueryType, GetUserValidationQueryVariables>(GetUserValidationQuery, options);
        }
export type GetUserValidationQueryHookResult = ReturnType<typeof useGetUserValidationQuery>;
export type GetUserValidationLazyQueryHookResult = ReturnType<typeof useGetUserValidationLazyQuery>;
export type GetUserValidationQueryResult = Apollo.QueryResult<GetUserValidationQueryType, GetUserValidationQueryVariables>;
export const SubmitContactFormQuery = gql`
    mutation submitContactForm($input: SubmitContactFormInput!) {
  submitContactForm(input: $input) {
    status
  }
}
    `;
export type SubmitContactFormMutationFn = Apollo.MutationFunction<SubmitContactFormMutationType, SubmitContactFormMutationVariables>;

/**
 * __useSubmitContactFormMutation__
 *
 * To run a mutation, you first call `useSubmitContactFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitContactFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitContactFormMutation, { data, loading, error }] = useSubmitContactFormMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSubmitContactFormMutation(baseOptions?: Apollo.MutationHookOptions<SubmitContactFormMutationType, SubmitContactFormMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitContactFormMutationType, SubmitContactFormMutationVariables>(SubmitContactFormQuery, options);
      }
export type SubmitContactFormMutationHookResult = ReturnType<typeof useSubmitContactFormMutation>;
export type SubmitContactFormMutationResult = Apollo.MutationResult<SubmitContactFormMutationType>;
export type SubmitContactFormMutationOptions = Apollo.BaseMutationOptions<SubmitContactFormMutationType, SubmitContactFormMutationVariables>;