export const getDomain = () => {
  if (typeof window !== "undefined") {
    // This regex matches for domain specifically when ran against window.location.hostname
    const regex = /(?:www\.){0,1}([\w\-\_\.]+(?:com|net|test|org))/;
    const match = window.location.hostname.match(regex);
    if (match && match[1]) {
      return match[1];
    }
  }
};

const domain = getDomain() || "xometry.com";

const isLocalDev = domain == "xometry.test";
const protocol = isLocalDev ? "http" : "https";

const wwwSubdomain = "www";
const getSubdomain = "get";
const workSubdomain = "work";
const authSubdomain = "auth";
const apiSubdomain = "api";
const pfsSubdomain = "us-files";
const hostedDomain = isLocalDev ? "xometry.net" : domain;
const stageSubDomain = isLocalDev ? ".stage" : "";

const marketplacePort = 3000;
const marketingPort = 8000;
const getPort = 5001;
const workPort = 5002;
const graphqlPort = 8001;

const www = `${protocol}://${wwwSubdomain}.${domain}${
  isLocalDev ? `:${marketingPort}` : ""
}`;

const get = `${protocol}://${getSubdomain}.${domain}${
  isLocalDev ? `:${getPort}` : ""
}`;

const work = `${protocol}://${workSubdomain}.${domain}${
  isLocalDev ? `:${workPort}` : ""
}`;

const auth = `https://${authSubdomain}${stageSubDomain}.${hostedDomain}`;
const hosted = `https://${wwwSubdomain}${stageSubDomain}.${hostedDomain}`;
const api = `https://${apiSubdomain}${stageSubDomain}.${hostedDomain}`;
const marketplace = `https://${wwwSubdomain}${stageSubDomain}.${hostedDomain}`;
const pfs = `${protocol}://${pfsSubdomain}.${domain}`;
const graphql = `${protocol}://${wwwSubdomain}.${domain}${
  isLocalDev ? `:${graphqlPort}/graphql` : "/api/graphql/"
}`;

export const origins = {
  www: www,
  get: get,
  work: work,
  auth: auth,
  hosted: hosted,
  marketplace: marketplace,
  api: api,
  graphql,
  pfs,
};

// Converts an absolute link such as https://www.xometry.com/abc
// to a link that corresponds to the current environment.
// Example: https://www.xometry.com/abc --> https://www.staging.xometry.net/abc
export const buildUrl = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (_) {
    if (!urlString) return "/404";
    return urlString;
  }

  const { pathname, hash, search } = url;
  const [subdomain, host, topLevelDomain] = url.host.split(".");

  // Build url if it is a xometry link
  if (
    host === "xometry" &&
    topLevelDomain !== "org" &&
    (subdomain === wwwSubdomain ||
      subdomain === getSubdomain ||
      subdomain === workSubdomain)
  ) {
    // Returns the new url by using the subdomain variable ('www', 'get', 'work') as key for the
    // origins object. This is possible because of the second half of the if statement above
    // that checks for the subdomain variable to be one of the keys in the origins object.
    return `${origins[subdomain]}${pathname}${search}${hash}`;
  }

  return urlString;
};

// Converts an absolute link such as https://www.xometry.com/abc
// to a relative link. Only converts a link if has a xometry host, www subdomain and .com TLD
// Example: https://www.xometry.com/abc --> /abc
export const buildRelativeUrl = (urlString: string) => {
  let url;
  try {
    url = new URL(urlString);
  } catch (_) {
    if (!urlString) return "/404";
    return urlString;
  }

  const { pathname, hash, search } = url;
  const [subdomain, host, topLevelDomain] = url.host.split(".");

  // Relative url if it is a xometry link on www
  if (
    host === "xometry" &&
    topLevelDomain === "com" &&
    subdomain === wwwSubdomain
  ) {
    return `${pathname}${search}${hash}`;
  }

  return urlString;
};

export const buildUrlsOnPage = () => {
  for (const link of document.links as any) {
    link.href = buildUrl(link.href);
  }
};
