module.exports = [{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-prismic-previews-virtual-0e1323dc70/0/cache/gatsby-plugin-prismic-previews-npm-6.0.0-56911b03d6-a53c031507.zip/node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"xometry-marketing","accessToken":"MC5YbWtIVEJJQUFDSUFmY2lk.C--_vVrvv700Vzbvv70TEe-_ve-_vRESWzDvv73vv73vv71n77-9Au-_vR1U77-9VO-_ve-_vUDvv71Y"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-google-tagmanager-virtual-d25e4752da/0/cache/gatsby-plugin-google-tagmanager-npm-5.11.0-c7cabd413a-a8f7238071.zip/node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-TMCCM7","includeInDevelopment":true,"routeChangeEventName":"marketing-site-route-change","defaultDataLayer":null,"enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../.yarn/__virtual__/gatsby-plugin-gatsby-cloud-virtual-4087ccdc92/0/cache/gatsby-plugin-gatsby-cloud-npm-5.11.0-d3c4a1212e-708c932f81.zip/node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../.yarn/unplugged/gatsby-virtual-62cd33fe5c/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
