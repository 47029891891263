import { CookiesProvider } from "react-cookie";
import { buildUrlsOnPage } from "./src/utils/environment-urls";
import { PrismicPreviewProvider } from "gatsby-plugin-prismic-previews";
import { prismicPreviewConfig } from "./src/templates";
import { GatsbyBrowser } from "gatsby";
import React from "react";
import { PrismicToolbar } from "@prismicio/react";
import { prismicRepo } from "./src/utils/constants";
import { UserValidationContextStore } from "./src/utils/user-validation-context";
import { ApolloProvider } from "@apollo/client";
import apolloClient from "./src/utils/apollo";

// Start mock service worker
// https://mswjs.io/docs/getting-started/integrate/browser
if (process.env.NODE_ENV === "development") {
  const { worker } = require("./mocks/browser");
  worker.start();
}

// update meta og:url and canonical link tags with relative URLs
const onRouteUpdate: GatsbyBrowser["onRouteUpdate"] = ({ location }) => {
  // if path is localized to default language, use non-localized path
  let pathName = `${location.pathname}`;
  let defaultLangPath = "/en-US";
  if (pathName.startsWith(defaultLangPath)) {
    pathName = pathName.substring(defaultLangPath.length);
  }

  let url = `${location.origin}${pathName}${location.hash}`;

  const ogUrl = document.createElement("meta");
  ogUrl.setAttribute("property", "og:url");
  ogUrl.setAttribute("content", url);

  const canonicalLink = document.createElement("link");
  canonicalLink.setAttribute("rel", "canonical");
  canonicalLink.setAttribute("href", url);

  const header = document.querySelector("head");
  header?.appendChild(ogUrl);
  header?.appendChild(canonicalLink);

  buildUrlsOnPage();
};

const wrapRootElement: GatsbyBrowser["wrapRootElement"] = ({ element }) => {
  const isPreviewPage =
    location.pathname.includes("/preview") ||
    document.referrer.includes("/preview");
  return (
    <PrismicPreviewProvider repositoryConfigs={[prismicPreviewConfig]}>
      <ApolloProvider client={apolloClient}>
        <CookiesProvider>
          <UserValidationContextStore>{element}</UserValidationContextStore>
        </CookiesProvider>
      </ApolloProvider>
      {isPreviewPage && <PrismicToolbar repositoryName={prismicRepo} />}
    </PrismicPreviewProvider>
  );
};

const wrapPageElement: GatsbyBrowser["wrapPageElement"] = ({
  element,
  props,
}) => {
  typeof window !== "undefined" &&
    window.analytics &&
    window.analytics.page("Marketing", {
      template: props.pageContext.type,
      prismicId: props.pageContext.prismicId,
    });
  return element;
};

export { onRouteUpdate, wrapRootElement, wrapPageElement };

export const onInitialClientRender: GatsbyBrowser["onInitialClientRender"] =
  () => {
    window.addEventListener("segmentLoaded", function () {
      // Adjust drift bot location to be above privacy banner if it is present
      window.analytics &&
        window.analytics.ready &&
        window.analytics.ready(function () {
          window?.drift?.on("ready", function () {
            const privacyBanner = document.getElementById("privacy-banner");
            const botController = document.getElementById(
              "drift-frame-controller"
            );
            const botChat = document.getElementById("drift-frame-chat");

            if (privacyBanner && botController && botChat) {
              botController.style.bottom =
                parseInt(botController.style.bottom) +
                privacyBanner.offsetHeight +
                "px";
              botChat.style.bottom =
                parseInt(botChat.style.bottom) +
                privacyBanner.offsetHeight +
                "px";
              botChat.style.zIndex = "9000 !important";
            }
          });
        });
    });
  };

export const shouldUpdateScroll: GatsbyBrowser["shouldUpdateScroll"] = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  const currentPosition = getSavedScrollPosition(location);

  setTimeout(() => {
    window.scrollTo(...(currentPosition || [0, 0]));
  }, 0);

  return false;
};
