import React, { createContext } from "react";
import {
  GetUserValidationQueryType,
  useGetUserValidationQuery,
} from "../types/queries";

type Me = GetUserValidationQueryType["me"];
type ExpiredMe = GetUserValidationQueryType["expiredMe"];

export interface UserValidation {
  // Indicates if user data has completed loading
  isReady: boolean;
  // User token is valid
  isValid?: boolean;
  // User token is expired
  isExpired?: boolean;
  userData?: Me & ExpiredMe;
}

const initialUserValidationValue = {
  isReady: false,
};

export const UserValidationContext = createContext<UserValidation>(
  initialUserValidationValue
);

export const UserValidationContextStore = ({ children }: any) => {
  const { error, data } = useGetUserValidationQuery();

  let userValidationData: UserValidation = {
    isReady: false,
  };
  if (error) {
    userValidationData = {
      isReady: true,
      isValid: false,
    };
  } else if (data) {
    if (data.me) {
      // "me" returned data, so user has a valid unexpired token
      userValidationData = {
        isReady: true,
        isValid: true,
        isExpired: false,
        userData: data.me,
      };
      window.analytics.identify(data.me.id, { email: data.me.emailAddress });
    } else if (data.expiredMe) {
      // expiredMe returned data, so user has a valid expired token
      userValidationData = {
        isReady: true,
        isValid: true,
        isExpired: true,
        userData: data.expiredMe,
      };
      // me and expiredMe were null, so token must be invalid & expired
    } else {
      userValidationData = {
        isReady: true,
        isValid: false,
      };
    }
  }

  return (
    <UserValidationContext.Provider value={userValidationData}>
      {children}
    </UserValidationContext.Provider>
  );
};
