const isEnvObjectSet = typeof window !== "undefined" && window.__env__;

export const splitAPIKey = isEnvObjectSet && window.__env__.SPLIT_API_KEY;
export const robotMetaTag = isEnvObjectSet && window.__env__.ROBOT_META_TAG;
export const algoliaAPPID = isEnvObjectSet && window.__env__.ALGOLIA_APP_ID;
export const algoliaSearchAPIKey =
  isEnvObjectSet && window.__env__.ALGOLIA_SEARCH_API_KEY;
export const prismicAlgoliaIndexName =
  isEnvObjectSet && window.__env__.PRISMIC_ALGOLIA_INDEX_NAME;
export const helpCenterAlgoliaIndexName =
  isEnvObjectSet && window.__env__.HELP_CENTER_ALGOLIA_INDEX_NAME;
export const baseDomain = isEnvObjectSet && window.__env__.BASE_DOMAIN;
export const workcenterBaseDomain = isEnvObjectSet && window.__env__.WORKCENTER_BASE_DOMAIN;
export const hubspotFormSubmitEnabled: boolean =
  isEnvObjectSet && window.__env__.HUBSPOT_FORM_SUBMIT_ENABLED === "true";
export const hubspotPortalId =
  isEnvObjectSet && window.__env__.HUBSPOT_PORTAL_ID;
export const hubspotFormOverride =
  isEnvObjectSet && window.__env__.HUBSPOT_FORM_OVERRIDE;
export const hubspotFormIdOverride =
  isEnvObjectSet && window.__env__.HUBSPOT_FORM_ID_OVERRIDE;
export const hubspotNewsletterFormId =
  isEnvObjectSet && window.__env__.HUBSPOT_NEWSLETTER_FORM_ID;
export const prismicRepo = isEnvObjectSet && window.__env__.PRISMIC_REPO_NAME;
export const contactFormId = isEnvObjectSet && window.__env__.CONTACT_FORM_ID;

// As the name implies, it sets the number of results per page.
// Is also used to calculate pagination metadata
export const hitsPerPage = 10;

export const helpCenterURLBase = "https://community.xometry.com/kb/articles/";

export const youtubeRegEx =
  /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;

// Used as base for capability breadcrumbs
export const capabilityBreadCrumb = {
  name: "Capabilities",
  url: "/capabilities/",
};
