import React from "react";
import { prismicRepo } from "../utils/constants";
import { linkTo } from "../utils/contentUtils";
import { RepositoryConfig } from "gatsby-plugin-prismic-previews";

export const prismicPreviewConfig: RepositoryConfig = {
  repositoryName: prismicRepo,
  linkResolver: linkTo,
  componentResolver: {
    capability: React.lazy(() => import("./capability/capability")),
    content_category: React.lazy(
      () => import("./content-category/content-category")
    ),
    content_hub: React.lazy(() => import("./content-hub/content-hub")),
    content_post: React.lazy(() => import("./content-post/content-post")),
    home: React.lazy(() => import("./home/home")),
    short_landing_page: React.lazy(
      () => import("./short-landing-page/short-landing-page")
    ),
    standard_landing_page: React.lazy(
      () => import("./standard-landing-page/standard-landing-page")
    ),
  },
};
