import {
  PrismicLinkField,
  PrismicContentPostData,
  ContentPostFragment,
  RichTextSliceFragment,
} from "../types/graphql";
import readingTime from "reading-time";
import * as prismicHelper from "@prismicio/helpers";
import { replaceWithDashes } from "./replaceWithDashes";
import { RTLinkNode } from "@prismicio/types";
import { isMobile } from "react-device-detect";
import { buildUrl, origins } from "./environment-urls";
import { PostsByCategory } from "../templates/content-hub/content-hub";
import * as FA from "../styles/fontawesome";

export function getPublicationDate(
  doc: ContentPostFragment | PostsByCategory["posts"][number]["node"]
): string {
  // Use override publication_date_display, fallback to publication_date_override/first_publication_date for previews
  if (doc.fields?.publication_date_display) {
    return doc.fields?.publication_date_display;
  }

  const fallbackDate =
    new Date(doc.data.publication_date_override) ||
    new Date(doc.first_publication_date) ||
    Date.now();

  // format to "MMMM D, YYYY" e.g. January 21, 2022
  return fallbackDate.toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function getEditedDate(
  doc: ContentPostFragment | PostsByCategory["posts"][number]["node"]
): string {
  // Use override publication_date_display, fallback to publication_date_override/last_publication_date for previews
  if (doc.fields?.edited_date_display) {
    return doc.fields?.edited_date_display;
  }

  const fallbackDate =
    new Date(doc.data.publication_date_override) ||
    new Date(doc.last_publication_date) ||
    Date.now();

  // format to "MMMM D, YYYY" e.g. January 21, 2022
  return fallbackDate.toLocaleDateString("en-us", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

export function navigateToUrl(
  link: string | null | undefined,
  target: string = "_self"
) {
  if (!link) {
    return;
  }
  /* 
   The quoting engine is not optimized for mobile. Until that is the case, 
   redirect the user to a Hubspot Landing page to capture their email and 
   remind them to quote on a desktop device
  */
  const getPageRegex = /.*get.xometry.com.*$/;
  const loginPageRegex = /^.*get.xometry.com\/login.*$/;

  // On mobile get pages (other than /login) redirect to
  // /register as defined in APP-18950 https://bit.ly/3ftNiqR
  if (isMobile && getPageRegex.test(link) && !loginPageRegex.test(link)) {
    window.location.href = `${origins.get}/register`;
  } else {
    window.open(buildUrl(link), target || "_self");
  }
}

// Function to convert text to dash-case
export function textToHTMLAttribute(text: string) {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^\s\w]/g, "")
    .replace(/\s/g, "-");
}

// Function to extract rich text slices from content post slice array and return the readtime
export function contentPostReadTime(
  slices: ContentPostFragment | PostsByCategory["posts"][number]["node"]
) {
  let postText = "";
  slices.data?.body1?.forEach((slice) => {
    if (
      "primary" in slice &&
      "rich_text" in slice.primary &&
      slice.primary?.rich_text
    ) {
      postText += prismicHelper.asText(slice.primary?.rich_text.richText);
    }
  });
  return postText && `${readingTime(postText).minutes} min read`;
}

export function contentIconStyle(
  content_type: PrismicContentPostData["content_type"]
) {
  switch (content_type) {
    case "Article":
      return FA.FileLinesRegular;
    case "Video":
      return FA.CirclePlayRegular;
    case "Podcast":
      return FA.HeadphonesRegular;
    case "Download":
      return FA.CloudArrowDownRegular;
  }
  return FA.FileLinesRegular;
}

type LinkToDocType =
  | Pick<PrismicLinkField, "uid" | "type">
  | RTLinkNode["data"];

export function linkTo(doc: LinkToDocType) {
  let path = "/";
  if (!doc) return path;
  if (!("uid" in doc) && !("type" in doc)) {
    return path;
  }

  switch (doc.type) {
    case "short_landing_page":
      path = `/` + doc.uid;
      break;
    case "standard_landing_page":
      const standardLandingGrandParentUid =
        (doc as any).data?.parent_id?.document?.data?.parent_id?.uid ||
        (doc as any).data?.parent_id?.data?.parent_id?.uid;
      if (standardLandingGrandParentUid) {
        path += `${standardLandingGrandParentUid}/`;
      }
      const standardLandingParentUid =
        (doc as any).document?.data?.parent_id?.uid ||
        (doc as any).data?.parent_id?.uid;
      if (standardLandingParentUid) {
        path += `${standardLandingParentUid}/`;
      }
      path += `${doc.uid}`;
      break;
    case "capability":
      const parentUid =
        (doc as any).data?.parent_id?.document?.uid ||
        (doc as any).data?.parent_id?.uid ||
        (doc as any).document?.data.parent_id?.uid;
      path = `/capabilities/${parentUid ? `${parentUid}/` : ""}${doc.uid}`;
      break;
    case "content_post":
      // When previewing, category data isn't wrapped in "document" field
      let category =
        (doc as any).data?.category?.document?.uid ||
        (doc as any).data?.category?.uid;
      path = `/resources/${category ? `${category}/` : ""}${doc.uid}`;
      break;
    case "content_category":
      path = `/resources/` + doc.uid;
      break;
    case "content_hub":
      path = `/resources`;
      break;
    case "home":
      path = `/`;
      break;
  }
  // Add a trailing slash to end of path if one does not exist
  path = path.match(/\/$/) ? path : path + "/";
  return path;
}

// Returns the url for a landing page group page
export const linkToPageGroup = (
  capability: LinkToDocType,
  region: string,
  city?: string | null
) => {
  let result;
  const capabilityPathSegment = linkTo(capability);
  const regionPathSegment = `${replaceWithDashes(region)}/`;
  result = capabilityPathSegment + regionPathSegment;

  if (city) {
    const cityPathSegment = `${replaceWithDashes(city)}/`;
    result += cityPathSegment;
  }
  // Cleanup path by removing commas and periods
  result = result.replace(/,|\./g, "");
  return result;
};
